import React, { useState, useEffect } from "react";
import { Carousel } from "3d-react-carousal";
import "./About.css";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

let slides = [
  <img src={"/images/image-2.png"} alt="" />,
  <img src={"/images/image-1.png"} alt="" />,
  <img src={"/images/image-3.png"} alt="" />,
];

function About() {
  const { t } = useTranslation();
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    mobileVer();
  }, []);

  const mobileVer = () => {
    if (window.innerWidth <= 960) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  window.addEventListener("resize", mobileVer);

  return (
    <>
      {mobile ? (
        <div className="container-about">
          <div className="slide">
            <Carousel
              slides={[
                <img src={"/images/image-2.png"} alt="" />,
                <img src={"/images/image-1.png"} alt="" />,
                <img src={"/images/image-3.png"} alt="" />,
              ]}
              arrows={false}
            />
          </div>
          <div>
            <p className="desc-about-company">{t("desc_about_us")}</p>
          </div>
          <div>
            <div className="border-title-company" />
            <div className="subtitle">{t("vission")}</div>
            <div className="detail-text">{t("vission_company")}</div>
          </div>
          <div style={{ paddingTop: "30px" }}>
            <div className="border-title-company" />
            <div className="subtitle">{t("mission")}</div>
            <div className="detail-text">{t("mission_company")}</div>
          </div>
          <div style={{ paddingTop: "30px" }}>
            <div className="border-title-company" />
            <div className="subtitle">{t("membership")}</div>
            <div className="about-pic-container-mobile">
              <img
                className="member-pic"
                src={"/images/apbi-icma.png"}
                alt="apbi-icma"
              />
              <img className="member-pic" src={"/images/aisi.png"} alt="aisi" />
              <img
                className="member-pic"
                src={"/images/fosfa-international.png"}
                alt="fosfa-international"
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="slide">
            <Carousel slides={slides} arrows={true} />
          </div>
          <p className="desc-about-company">{t("desc_about_us")}</p>
          <Row className="detail-about">
            <Col>
              <Row className="border-title-company" />
              <Row className="subtitle">{t("vission")}</Row>
              <Row className="detail-text">{t("vission_company")}</Row>
            </Col>
            <Col md={{ span: 1, offset: 0 }} />
            <Col>
              <Row className="border-title-company" />
              <Row className="subtitle">{t("mission")}</Row>
              <Row className="detail-text">{t("mission_company")}</Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="border-title-member" />
              <Row className="subtitle">{t("membership")}</Row>
              <Row style={{ margin: "auto" }}>
                <Col>
                  <img src={"/images/apbi-icma.png"} alt="apbi-icma" />,
                </Col>
                <Col>
                  <img src={"/images/aisi.png"} alt="aisi" />,
                </Col>
                <Col>
                  <img
                    src={"/images/fosfa-international.png"}
                    alt="fosfa-international"
                  />
                  ,
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default About;
