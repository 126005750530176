import React from "react";
import "./Definition.css";
import { useTranslation } from "react-i18next";

function Commitment() {
  const { t } = useTranslation();

  return (
    <>
      <div className="tkdn-container-definition">
        <div className="tkdn-section-header">
          <div className="border-title-vpti" />
          <h1 className="tkdn-section-title">{t("commitment_title")}</h1>
        </div>
        <p>{t("commitment_desc")}</p>
        
      </div>
    </>
  );
}
export default Commitment;
